import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

// components
import CampioneGratuitoForm from "../../components/campione-gratuito-form";
import Hero from "../../utils/hero";
import Page from "../../utils/page";
import theme from "../../utils/theme";

// markup
const MuestraGratuitaPage = () => {
  return (
    <Page
      lang="es"
      title="Muestra gratuita de toallitas húmedas desechables y baberos"
      description="Pruebe nuestras toallitas húmedas desechables y baberos no tejidos. Su kit incluye: 8 toallitas (1 para cada fragancia), 4 baberos (1 para cada formato)."
      type="ContactPage"
      image="/richiedi-campione-gratuito.jpg"
    >
      <Hero
        title="Muestra gratuita"
        description={
          <>
            Pruebe nuestras <strong>toallitas húmedas desechables</strong> y{" "}
            <strong>baberos no tejidos</strong>.
          </>
        }
        background={theme.palette.primary.main}
        backgroundImage={
          <StaticImage
            style={{ height: "100%" }}
            quality={100}
            src="../../images/backgrounds/fragranza-menta.jpg"
            alt="Fragancia de menta"
            layout="fullWidth"
          />
        }
      />

      <CampioneGratuitoForm language="es" />
    </Page>
  );
};

export default MuestraGratuitaPage;
